/*eslint-disable*/
// import moment from 'moment'
import isEmpty from "lodash/isEmpty"
import service from "../services/apiService"
import encryptor from "../plugins/encryption"
import hostName from "~/plugins/hostname"
export const state = () => ({
    data: {},
    loading: false,
    loggedIn: false,
    apiResponse: {},
    error: {},
    channelId: "",
    apiVersion: "",
    userId: "",
    show: "t",
    valueChanged: false,
    notificationData: {
        count: 0,
        notifications: []
    },
    underMaintenance: { value: false, checked: false },
    showMaintenanceModal: false,
    walletUnderMaintenance: { value: false, checked: false },
    appversion: {}
})

export const mutations = {
    set_error(state, data) {
        state.error = data
    },
    set_show(state, value) {
        state.show = value
    },
    set_data(state, data) {
        state.data = data
    },
    set_login(state, data) {
        state.loggedIn = data
    },
    set_apiResponse(state, value) {
        state.apiResponse = value
    },
    set_appversion(state, value) {
        if (value && value.status && value.status === 200) {
            state.appversion = value
        } else {
            value.projectCode = process.env.setUp
            state.appversion = value
        }
    },
    vmodel_data(state, data) {
        state.data[data.vModel] = data.value
        if (data.vModel === "dob") {
            state.valueChanged = true
        }
    },
    SET_CHANNEL_ID(state, data) {
        state.channelId = data
    },
    SET_USER_ID(state, data) {
        state.userId = data._id
    },
    set_notificationData(state, data) {
        state.notificationData = data
    },
    set_notifications_count(state, count) {
        state.notificationData.count = count
    },
    set_maintenance(state, value) {
        state.underMaintenance = { value, checked: true }
    },
    set_showMaintenanceModal(state, value) {
        state.showMaintenanceModal = value
    },
    set_walletUnderMaintenance(state, value) {
        state.walletUnderMaintenance = { value, checked: true }
    },
    SET_WALLET_MODAL(state) {
        const userData = state.data
        userData.walletModal = true
        state.data = userData
    },
    SET_INPLAY_MODAL(state) {
        const userData = state.data
        userData.inplayModal = true
        state.data = userData
    },
    SET_COOKIES_MODAL(state) {
        const userData = state.data
        userData.cookiesModal = true
        state.data = userData
    },
    SET_API_VERSION(state, value) {
        state.apiVersion = value
    }
}
export const actions = {
    async getProfile({ commit }) {
        try {
            if (
                !localStorage ||
                !localStorage.getItem("accessToken") ||
                !this.$auth
            ) {
                return
            }
            if (sessionStorage && sessionStorage.getItem("getOneUser")) {
                let res = JSON.parse(
                    encryptor.decrypt(sessionStorage.getItem("getOneUser"))
                )
                if (res && res.status === 200) {
                    commit("set_login", true)
                    commit("set_data", res.data.userData)
                    if (
                        res.data &&
                        res.data.userData &&
                        res.data.userData.whatsAppLink
                    ) {
                        sessionStorage.setItem(
                            "depositWhatsapp",
                            res.data.userData.whatsAppLink
                        )
                    }
                    if (res.data.userData.dob) {
                        commit("set_show", "")
                    } else {
                        commit("set_show", "t")
                    }
                    // commit('SET_CHANNEL_ID', res.data.relationData)
                    // commit('SET_USER_ID', res.data.userData)
                }
            } else {
                const res = await service.apiCallWithHeader("/User/getOneUser")
                if (res && res.status === 200) {
                    sessionStorage.setItem(
                        "getOneUser",
                        encryptor.encrypt(JSON.stringify(res))
                    )
                    if (
                        res &&
                        res.data &&
                        res.data.userData &&
                        res.data.userData.affiliate &&
                        res.data.userData.affiliate !== ""
                    ) {
                        localStorage.setItem(
                            "user_affilates",
                            res.data.userData.affiliate
                        )
                    }
                    if (
                        res.data &&
                        res.data.userData &&
                        res.data.userData.realDeviceId
                    ) {
                        sessionStorage.setItem(
                            "realDeviceId",
                            JSON.stringify(res.data.userData.realDeviceId) ||
                                null
                        )
                        // const hostname = process.env.dynamicDomain
                        //     ? "localhost"
                        //     : window.location.hostname
                        // const mainDomainLink =
                        //     hostname && hostname === "localhost"
                        //         ? process.env.basicDomain
                        //         : hostname &&
                        //           (hostname.includes("m.") ||
                        //               hostname.includes("bd.") ||
                        //               hostname.includes("www.") ||
                        //               hostname.includes("fantasy."))
                        //         ? hostname.replace(/m.|www.|bd.|fantasy./gi, "")
                        //         : hostname
                        var mainDomainLink = hostName.DomainCheck(
                            window.location.hostname
                        )

                        localStorage.setItem(
                            "userAdminUrl",
                            process.env.userAdminUrl + mainDomainLink
                        )
                        if (
                            res.data &&
                            res.data.userData &&
                            res.data.userData.whatsAppLink
                        ) {
                            sessionStorage.setItem(
                                "depositWhatsapp",
                                res.data.userData.whatsAppLink
                            )
                        }
                    }
                    commit("set_login", true)
                    commit("set_data", res.data.userData)
                    if (res.data.userData.dob) {
                        commit("set_show", "")
                    } else {
                        commit("set_show", "t")
                    }
                    // commit('SET_CHANNEL_ID', res.data.relationData)
                    // commit('SET_USER_ID', res.data.userData)
                } else {
                    return "Failed"
                }
            }
        } catch (err) {}
    },
    async getProfileForBonusSocket({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }

            const res = await service.apiCallWithHeader("/User/getOneUser")
            if (res && res.status === 200) {
                sessionStorage.setItem(
                    "getOneUser",
                    encryptor.encrypt(JSON.stringify(res))
                )
                if (
                    res &&
                    res.data &&
                    res.data.userData &&
                    res.data.userData.affiliate &&
                    res.data.userData.affiliate !== ""
                ) {
                    localStorage.setItem(
                        "user_affilates",
                        res.data.userData.affiliate
                    )
                }
                commit("set_login", true)
                commit("set_data", res.data.userData)
                if (res.data.userData.dob) {
                    commit("set_show", "")
                } else {
                    commit("set_show", "t")
                }
                // commit('SET_CHANNEL_ID', res.data.relationData)
                // commit('SET_USER_ID', res.data.userData)
            }
        } catch (err) {}
    },
    async markAllNotificationsAsRead() {
        try {
            await service.apiCallWithHeader("/User/markAllNotificationsAsRead")
        } catch (err) {}
    },
    async getNotifications({ commit }) {
        try {
            if (!localStorage || !localStorage.getItem("accessToken")) {
                return
            }
            const res = await service.apiCallWithHeader(
                "/User/getNotifications"
            )
            if (res && res.status === 200 && res.data) {
                commit("set_notificationData", res.data)
            }
        } catch (err) {}
    },
    async updateProfile({ commit }, updateData) {
        try {
            const res = await service.apiCallWithUserManagementWithHeader(
                "/v2/user/profileupdate",
                updateData
            )
            return res
            // if (res && res.status === 200 && res.data === 'updated successfully') {
            //   commit('set_apiResponse', {
            //     response: 'success',
            //     message: 'Updated successfully.'
            //   })
            // } else {
            //   commit('set_apiResponse', {
            //     response: 'error',
            //     message: res.data
            //   })
            // }
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while updating profile, try again"
            })
        }
    },
    async verifiedUpdateProfile({ commit }, updateData) {
        try {
            const res = await service.apiCallWithUserManagementWithHeader(
                "/v2/user/verifyprofileupdate",
                updateData
            )
            return res
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while updating profile, try again"
            })
        }
    },
    async verifyOtp({ commit }, data) {
        try {
            return await service.apiCallWithUserManagementWithHeader(
                "/v2/verifyotp",
                data
            )
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while updating profile, try again"
            })
        }
    },
    async changePassword({ commit }, credentails) {
        try {
            const res = await service.apiCallWithHeader(
                "/User/cognitoChangePassword",
                credentails
            )
            if (
                res &&
                res.status === 200 &&
                res.data === "Password Changed Successfully!"
            ) {
                commit("set_apiResponse", {
                    response: "success",
                    message: res.data
                })
            } else {
                commit("set_apiResponse", {
                    response: "error",
                    message: res.data.message || "Authentication Failed!"
                })
            }
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while updating password, try again"
            })
        }
    },
    async getMaintenance({ commit }) {
        try {
            // This will stored data for 5mins.
            if (
                sessionStorage.getItem("set_MaintenanceStatus_timezone") &&
                sessionStorage.getItem("set_maintenance") &&
                sessionStorage.getItem("set_walletUnderMaintenance")
            ) {
                const aa = sessionStorage.getItem(
                    "set_MaintenanceStatus_timezone"
                )
                const now = new Date().getTime()
                const distance = now - aa
                const seconds = Math.floor(distance / 1000)
                const minutes = Math.floor(seconds / 60)
                if (minutes >= 5) {
                    sessionStorage.removeItem("set_maintenance")
                    sessionStorage.removeItem("set_walletUnderMaintenance")
                    sessionStorage.removeItem("set_MaintenanceStatus_timezone")
                }
            }
            if (process.client) {
                if (sessionStorage.getItem("set_maintenance")) {
                    const data = await JSON.parse(
                        encryptor.decrypt(
                            sessionStorage.getItem("set_maintenance")
                        )
                    )
                    commit("set_maintenance", data)
                    if (data) this.$router.push("/maintenance")
                    if (!sessionStorage.getItem("set_walletUnderMaintenance")) {
                        return
                    }
                }
                if (sessionStorage.getItem("set_walletUnderMaintenance")) {
                    const data = await JSON.parse(
                        encryptor.decrypt(
                            sessionStorage.getItem("set_walletUnderMaintenance")
                        )
                    )
                    commit("set_walletUnderMaintenance", data)
                    if (data) {
                        this.$store.commit(
                            "profile/set_showMaintenanceModal",
                            true
                        )
                        this.$router.push("/")
                    }
                    return
                }
            }

            const res = await service.apiCallWithHeader(
                "/Maintenance/getWebisteMaintenance"
            )
            if (res && res.status === 200 && res.data) {
                commit("set_maintenance", res.data.maintenanceMode)
                commit(
                    "set_walletUnderMaintenance",
                    res.data.walletMaintenanceMode
                )
                sessionStorage.setItem(
                    "set_maintenance",
                    encryptor.encrypt(JSON.stringify(res.data.maintenanceMode))
                )
                sessionStorage.setItem(
                    "set_walletUnderMaintenance",
                    encryptor.encrypt(
                        JSON.stringify(res.data.walletMaintenanceMode)
                    )
                )
                if (res.data.maintenanceMode) this.$router.push("/maintenance")
                if (res.data.walletMaintenanceMode) {
                    this.$store.commit("profile/set_showMaintenanceModal", true)
                    this.$router.push("/")
                }
            } else {
                commit("set_maintenance", false)
                commit("set_walletUnderMaintenance", false)
                sessionStorage.setItem(
                    "set_maintenance",
                    encryptor.encrypt(JSON.stringify(false))
                )
                sessionStorage.setItem(
                    "set_walletUnderMaintenance",
                    encryptor.encrypt(JSON.stringify(false))
                )
            }
            sessionStorage.setItem(
                "set_MaintenanceStatus_timezone",
                new Date().getTime()
            )
        } catch (err) {
            commit("set_maintenance", false)
            commit("set_walletUnderMaintenance", false)
        }
    },
    async getDeploymentVersion({ commit }) {
        try {
            const res = await service.apiCallWithUserManagementWithoutData(
                "/v2/version"
            )
            if (res && res.status === 200 && res.data && res.data.uVersion) {
                // res.data.projectCode = 'fps'
                if (!res.data.projectCode) {
                    res.data.projectCode = "gb"
                }
                commit("set_appversion", res.data)
                // return res.data.data
            } else {
                commit("set_appversion", {})
                return "9999.9999.9999.9999"
            }
        } catch (err) {
            console.log("err", err, err.response)
            commit("set_error", err)
            return "9999.9999.9999.9999"
        }
    },
    async closeWalletModal({ commit }) {
        try {
            const response = await service.apiCallWithHeader(
                "/User/updateWalletModal"
            )
            if (response && response.status === 200) {
                commit("SET_WALLET_MODAL")
            }
        } catch (err) {}
    },

    async closeInplayModal({ commit }) {
        try {
            const response = await service.apiCallWithHeader(
                "/User/updateInplayModal"
            )
            if (response && response.status === 200) {
                commit("SET_INPLAY_MODAL")
            }
        } catch (err) {}
    },

    async closeCookiesModal({ commit }) {
        try {
            const response = await service.apiCallWithHeader(
                "/User/updateCookiesModal"
            )
            if (response && response.status && response.status === 200) {
                commit("SET_COOKIES_MODAL")
            }
        } catch (err) {}
    },
    async resetPassword({ commit }, data) {
        try {
            const response = await service.apiCallWithUserManagementWithHeader(
                "/v2/user/resetPassword",
                data
            )
            return response
        } catch (err) {
            commit("set_apiResponse", {
                response: "error",
                message:
                    err.message ||
                    "No Internet Connection while updating profile, try again"
            })
        }
    }
}

export const getters = {
    details(state) {
        if (isEmpty(state.data)) return
        const details = []
        if (state.data.name)
            details.push({ title: "name", subtitle: state.data.name })
        if (state.data.email)
            details.push({ title: "email", subtitle: state.data.email })

        details.push({ title: "mobile", subtitle: state.data.mobileNo || "" })

        details.push({ title: "gender", subtitle: state.data.gender || "" })

        // details.push({ title: "telegram", subtitle: state.data.telegram || "" })

        if (state.data.dob)
            details.push({
                title: "date of birth",
                subtitle: state.data.dob
            })
        return details
    }
}
