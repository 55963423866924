var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:_vm.$vuetify.theme.dark ? '' : 'light-pattern',style:(_vm.$vuetify.theme.dark
            ? ''
            : { backgroundImage: 'url(' + _vm.backgroundImage + ')' })},[_c('div',[_c(_vm.CacheClearModal,{tag:"component"})],1),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.shareModal,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.FairplayNotification,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.NetworkBar,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.$breakpoint.smAndDown)?_c('div',[_c(_vm.megaMenuDrawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.$breakpoint.smAndDown)?_c('div',[_c(_vm.mobileDrawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.Drawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.HeaderBar,{tag:"component"})],1):_vm._e(),_vm._v(" "),_c('v-main',{staticClass:"main-section",class:{
            'pb-15 pt-6': _vm.$breakpoint.smAndDown,
            'pt-10': !_vm.$breakpoint.smAndDown && !_vm.$auth.user,
            'mb-12': this.$route.name !== 'live-casino-system-pageNo'
        }},[_c(_vm.snackbar,{tag:"component"}),_vm._v(" "),_c('v-lazy',[_c('nuxt')],1)],1),_vm._v(" "),(
            (_vm.isLoaded && _vm.$route.name === 'index') ||
            _vm.$route.name === 'favorites' ||
            _vm.$route.name === 'inplay' ||
            _vm.$route.name === 'sportsbook-category' ||
            _vm.$route.name === 'sportsbook' ||
            _vm.$route.name === 'sportsbook-category-subCategory-id' ||
            _vm.$route.name === 'sportsbook-category-subCategory-id-betradar' ||
            _vm.$route.name === 'sportsbook-category-id'
        )?_c('div',[_c(_vm.OneclickBet,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.$breakpoint.smAndDown)?_c('div',{staticClass:"fav-marquee",attrs:{"id":"fav_marquee"}},[_c(_vm.FavoritesMarquee,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.setUp && _vm.setUp === 'gb' && _vm.domainType === 'hide')?_c('div',[_c(_vm.PlaceBetModal,{tag:"component"})],1):_vm._e(),_vm._v(" "),(
            _vm.isLoaded && _vm.$route.name && _vm.$route.name.startsWith('sportsbook')
        )?_c('div',[_c(_vm.LowBalanceModal,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.$breakpoint.smAndDown)?_c('div',[_c(_vm.navBar,{tag:"component",staticClass:"premium-topbar"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded)?_c('div',[_c(_vm.FooterBar,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.isLoaded && _vm.$auth.user && _vm.$auth.user._id)?_c('div',{staticClass:"d-none"},[_c(_vm.Ipquality,{tag:"component"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }